
.PrivacyPage__content {
    padding-bottom: 60px;
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.privacy-title,
.privacy-card h1,
.privacy-card div,
.privacy-card ul {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
}

.privacy-card ul {
    font-size: var(--fs-15);
    letter-spacing: 0;
    line-height: 18px;
    list-style: disc;
    margin-left: 10px;
    margin-top: 20px;
}

.privacy-title {
    font-size: var(--fs-25);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 59px !important;
}

.privacy-card h1 {
    font-size: var(--fs-base);
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px;
}

.privacy-card div {
    font-size: var(--fs-15);
    letter-spacing: 0;
    line-height: 18px;
    /* margin-top: 30px; */
}

.privacy-card ul li {
    padding: 0 0 0 20px;
}

@media (min-width: 1280px) {
    .privacy-title {
        font-size: var(--fs-title-m);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 59px;
        line-height: 59px;
        margin-top: 99px !important;
    }

    .privacy-card h1 {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .privacy-card div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
    }

    .privacy-card ul {
        font-size: var(--fs-base);
        line-height: 28px;
        margin-left: 10px;
        margin-top: 20px;
    }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .privacy-title {
        font-size: var(--fs-title-m);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 59px;
        line-height: 59px;
        margin-top: 99px !important;
    }

    .privacy-card h1 {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .privacy-card div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        /* margin-top: 30px; */
    }

    .privacy-card ul {
        font-size: var(--fs-base);
        line-height: 28px;
        margin-left: 10px;
        margin-top: 20px;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .privacy-title {
        font-size: var(--fs-35);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 41px;
        margin-top: 65px !important;
    }

    .privacy-card h1 {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .privacy-card div {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 30px;
        /* margin-top: 30px; */
    }

    .privacy-card ul {
        font-size: var(--fs-base);
        line-height: 28px;
        margin-left: 10px;
        margin-top: 20px;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

    .privacy-title {
        font-size: var(--fs-35);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 41px;
        margin-top: 65px !important;
    }

    .privacy-card h1 {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .privacy-card div {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
    }

    .privacy-card ul {
        font-size: var(--fs-base);
        line-height: 28px;
        margin-left: 10px;
        margin-top: 20px;
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/


