
.menu-social-buttons {
  display: flex;
  gap: 22px;
  justify-content: flex-end;
}

.menu-social-icon {
  height: 30px;
  width: 30px;
}


.menu-social-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media (min-width: 1280px) {
  .menu-social-buttons {
    gap: 30px;
  }

  .menu-social-icon {
    height: 40px;
    width: 40px;
  }
}