.Container__header.Container_container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header__logo {
    width: 8rem;
}

.header__menu_icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
}

@media (min-width: 1024px) {
    .header__logo {
        width: 17rem;
    }

    .burger-icon-position {
        display: flex;
        justify-content: flex-end;
    }

    .header__menu_icon {
        cursor: pointer;
        height: 50px;
        width: 50px;
    }

}
