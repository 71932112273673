.modal-card-body-bg {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 200px;
    justify-content: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-card-body-bg::-webkit-scrollbar {
    display: none;
} 

.menu-logos {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.menu-logos img,
.menu-logos svg {
    height: 70px;
    width: 70px;
}

.modal-card-body__jamenet-dots {
    display: flex;
    justify-content: flex-end;
}

.menu-ul {
    color: var(--font-color-primary);
    display: flex;
    flex-direction: column;
    font-family: var(--typography-content);
    font-size: var(--fs-40);
    font-weight: var(--font-weight-super-bold);
    gap: 30px;
    letter-spacing: 0;
    line-height: 47px;
    text-align: right;
    text-decoration: none;
    text-transform: uppercase;
}

.modal-card__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.cross-img {
    height: 50px;
    width: 50px;
}

.pull-cross-img {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 25px;
    justify-content: flex-end;
}

a {
    color: var(--font-color-primary);
    text-decoration: none;
}

a:hover {
    color: var(--font-color-primary);
    cursor:pointer;
    text-decoration:none;
}

.menu-brand-icon {
    height: 20px;
    width: 194px;
}

.menu-modal-div {
    left: 0px;
    position: absolute;
    top: 0px;
}

.modal-opacity {
    opacity: 0.9;
}

.background-container {
    position: relative;
}

.menu-ul li:hover {
    color: var(--font-color-hover-2);
}

.menu-ul a {
    color: var(--font-color-primary);
    text-decoration: none;
}

.menu-ul a:hover {
    color: var(--font-color-primary);
    cursor:pointer;
    text-decoration:none;
}

#langSelector a:link,
#langSelector a:visited,
#langSelector a:active, 
#langSelector {
    color: black !important;
    cursor: pointer;
    font-size: 45px;
    outline:none !important;
    text-decoration: none !important;
}

#langSelector a:hover{
    color: #414141;
}

@media (min-width: 1024px) {
    .modal-card__content {
        padding-top: 80px;
    }

    .menu-ul {
        font-size: var(--fs-54);
        font-weight: var(--font-weight-super-bold);
        line-height: 70px;
    }
}


@media (min-width: 1280px) {
    .menu-ul {
        font-size: var(--fs-72);
        line-height: 85px;
    }
}
