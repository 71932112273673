.whatWeDoCards__container {
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 50px;
}


@media (min-width: 480px) {
  .whatWeDoCards__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
}


@media (min-width: 1440px) {
  .whatWeDoCards__container {
      display: flex;
      justify-content: space-around;
  }
}