.error404 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-bottom: 100px;
}

.error404__title {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    font-size: var(--fs-45);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
}

.error404__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.error404__content-subtitle  {
    font-family: var(--typography-content);
    font-size: var(--fs-25);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 20px;
    max-width: 256px;
    text-align: center;
}

.error404__content-subtitle p {
    color: var(--font-color-primary);
}

.error404__content-subtitle-es {
    color:  var(--font-color-tertiary);
}

.error404__content-img {
    height: 146px;
    width: 72px;
}


@media (min-width: 1024px) {
    .error404__title {
        font-size: var(--fs-70);
        line-height: 82px;
    }

    .error404__content {
        display: flex;
        flex-direction: row;
        gap: 78px;
    }

    .error404__content-subtitle  {
        font-size: var(--fs-50);
        line-height: 59px;
        max-width: 760px;
    }

    .error404__content-img {
        height: 195px;
        width: 96px;
    }
}
