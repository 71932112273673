/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.is-footer-text  {
    color: var(--font-color-secondary);
    font-family: var(--typography-content);
}

@media (min-width: 1280px) {

    /* CSS */
    .footer-bg-error {
        background-color: var(--bg-darkest);
        height: 450px;
    }

    .is-footer-text {
        display: block;
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .footer-brand-img{
        float: right;
        height: 14px;
        width: 130px;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .icons-position a:hover {
        filter: brightness(0.7);
    }

    .error-404-white {
        color: var(--font-color-secondary);
        display: inline-block;
        float: right;
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 35px;
        text-align: right;
        width: 446px;
    }

    .error-404-gray {
        color: var(--font-color-secondary);
        display: inline-block;
        float: right;
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 35px;
        opacity: 0.7;
        text-align: right;
        width: 386px;
    }

    .error-404-contact-top {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-40);
        font-weight: var(--font-weight-bold);
        height: 47px;
        letter-spacing: 0;
        line-height: 47px;
        width: 366px;
    }

    .error-404-contact-bottom {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-40);
        font-weight: var(--font-weight-bold);
        height: 47px;
        letter-spacing: 0;
        line-height: 47px;
        width: 366px;
    }

    .error-footer-button {
        background-color: var(--bg-lightest);
        border-radius: 12px;
        height: 60px;
        width: 274px;
    }

    .is-center-button {
        display: flex;
        justify-content: center;
    }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

    /* CSS */
    .footer-bg-error {
        background-color: var(--bg-darkest);
        height: 450px;
    }

    .is-footer-text {
        color: var(--font-color-secondary);
        display: block;
        font-family: var(--typography-content);
        font-size: var(--fs-footer-s);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }


    .footer-brand-img{
        float: right;
        height: 14px;
        width: 130px;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .error-404-white {
        color: var(--font-color-secondary);
        display: inline-block;
        float: right;
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 35px;
        text-align: right;
        width: 446px;
    }

    .error-404-gray {
        color: var(--font-color-secondary);
        display: inline-block;
        float: right;
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 35px;
        opacity: 0.7;
        text-align: right;
        width: 386px;
    }

    .error-404-contact-top {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-40);
        font-weight: var(--font-weight-bold);
        height: 47px;
        letter-spacing: 0;
        line-height: 47px;
        width: 366px;
    }

    .error-404-contact-bottom {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-40);
        font-weight: var(--font-weight-bold);
        height: 47px;
        letter-spacing: 0;
        line-height: 47px;
        width: 366px;
    }

    .error-footer-button {
        background-color: var(--bg-lightest);
        border-radius: 12px;
        height: 60px;
        width: 274px;
    }

    .is-center-button {
        display: flex;
        justify-content: center;
    }

    .icons-position a:hover {
        filter: brightness(0.7);
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {

    /* CSS */
    /* CSS */
    .footer-bg-error {
        background-color: var(--bg-darkest);
        height: 258px;
    }

    .is-footer-text {
        color: var(--font-color-secondary);
        display: block;
        font-family: var(--typography-content);
        font-size: var(--fs-footer-s);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    #flex { display: flex; }
    #a { order: 2; }
    #b { order: 1; }
    #c { order: 3; }

    .icons-position {
        display: flex;
        justify-content: center;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 481px) and (max-width: 1023px) {

    /* CSS */
    .footer-bg-error {
        background-color: var(--bg-darkest);
        height: 549px;
    }

    .is-footer-text {
        color: var(--font-color-secondary);
        display: block;
        font-family: var(--typography-content);
        font-size: var(--fs-footer-s);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    #flex { display: flex; }
    #a { order: 2; width: 50%; }
    #b { order: 1; width: 100%;}
    #c { order: 3; width: 50%;}

    .column {
        padding-bottom: 0;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .error-footer-button {
        background-color: var(--bg-lightest);
        border-radius: 12px;
        height: 45px;
        margin-bottom: 32px;
        margin-top: 25px;
        width: 206px;
    }

    .error-404-white {
        background-color: var(--bg-lightest);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
    }

    .error-404-gray {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 22px;
        opacity: 0.7;
        text-align: center;
    }

    .error-404-contact-top {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-25);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 30px;
        margin-top: 17px;
        text-align: center;
    }

    .error-404-contact-bottom {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-25);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 30px;
        margin-top: 9px;
        text-align: center;
    }

    .is-center-button {
        display: flex;
        justify-content: center;
    }

    .error-footer-top-content {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .footer {
        padding: 3rem 2rem 1.5rem 2rem;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

}




/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    .footer-bg-error {
        background-color: var(--bg-darkest);
        height: 514px;
    }

    .is-footer-text {
        color: var(--font-color-secondary);
        display: block;
        font-family: var(--typography-content);
        font-size: var(--fs-footer-s);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    #flex { display: flex; }
    #a { order: 2; width: 50%; }
    #b { order: 1; width: 100%;}
    #c { order: 3; width: 50%;}

    .column {
        padding-bottom: 0;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .error-footer-button {
        background-color: var(--bg-lightest);
        border-radius: 12px;
        height: 45px;
        margin-bottom: 32px;
        margin-top: 25px;
        width: 206px;
    }

    .error-404-white {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
    }

    .error-404-gray {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 22px;
        opacity: 0.7;
        text-align: center;
    }

    .error-404-contact-top {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-25);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 30px;
        margin-top: 17px;
        text-align: center;
    }

    .error-404-contact-bottom {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-25);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 30px;
        margin-top: 9px;
        text-align: center;
    }

    .is-center-button {
        display: flex;
        justify-content: center;
    }

    .error-footer-top-content {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .footer {
        padding: 3rem 2rem 1.5rem 2rem;
    }
}
