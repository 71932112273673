.gallery {
    display: grid;
    gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    margin-bottom: 50px;
    margin-top:100px;
}

.gallery__img-container {
    position: relative;
}

.gallery__img-container-logo {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.gallery__img-container-bg {
    height: 100%;
    object-fit: cover;
    width: 100%;
}


@media (min-width: 1024px) {
    .gallery__img-container-bg:hover {
        filter:blur(2px);
        -webkit-filter:blur(2px);
    }

    .gallery__img-container-logo:hover + .gallery__img-container-bg {
        filter:blur(2px);
        -webkit-filter:blur(2px);
    }
}

@media (min-width: 1200px) {
    .gallery {
        grid-template-columns: repeat(3, minmax(25rem, 1fr));
    }
}