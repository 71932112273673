.Container_container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 1rem 1.25rem;
  width: 90vw;
}

.Container_container.Container__special-padding {
  padding: 2rem 1.25rem;
}

.Container_container--no-bottom-padding {
  padding-bottom: 0px;
}

/* .Container_container.Container_container--no-left-margin {
  margin-left: 0px;
} */

@media (min-width: 480px) {
  .Container_container {
    padding: 1.5rem;
  }
  .Container_container.Container__special-padding {
    padding: 1.5rem;
  }

  /* .Container_container.Container_container--no-left-margin {
    margin-left: 0px;
    padding: 0px 1.5rem 0px 0px ;
  } */
}

@media (min-width: 1024px) {
  .Container_container {
    padding: 2rem;
    width: 1024px;
  }
  .Container_container.Container__special-padding {
    padding: 2rem;
  }

  /* .Container_container.Container_container--no-left-margin {
    margin-left: 0px;
    padding: 0px 2rem 0px 0px ;
  } */
}

@media (min-width: 1440px) {
  .Container_container {
    padding: 4rem;
    width: 1440px;
  }

  .Container_container.Container__special-padding {
    padding: 4rem;
  }

  .Container_container--no-bottom-padding {
    padding-bottom: 0px;
  }

  /* .Container_container.Container_container--no-left-margin {
    margin-left: 0px;
    padding: 0px;
  } */
}

@media (min-width: 1680px) {
  .Container_container {
    width: 1680px;
  }
}

@media (min-width: 2000px) {
  .Container_container {
    width: 2000px;
  }
}
