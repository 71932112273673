
.whatWeDoCard {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 270px;
}

.whatwedocard__title {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    font-size: var(--fs-base);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.whatwedocard__description {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.whatwedocard__description p {
    color: var(--font-color-primary);
    display: inline-block;
    font-family: var(--typography-content);
    font-size: var(--fs-15);
    font-weight: var(--font-weight-regular);
    letter-spacing: 0;
    line-height: 18px;
}

.whatwedocard__logo {
    height: 50px;
    width: 50px;
}

.whatwedocard__dot {
    border-radius: 50%;
    height: 15px;
    width: 15px;
}

.whatwedocard__dot-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;;
}

.whatwedocard__top {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

@media (min-width: 481px) {
    .whatwedocard__title {
        font-size: var(--fs-25);
        line-height: 24px;
        margin: 0 auto;
    }

    .whatwedocard__description p {
        font-size: var(--fs-18);
        line-height: 25px;
    }
}

@media (min-width: 1024px) {
    .whatwedocard__title {
        margin: 0 auto;
        max-width: 235px;
    }

    .whatwedocard__description {
        gap: 30px;
    }

    .whatwedocard__dot {
        height: 23.21px;
        width: 23.21px;
    }

    .whatwedocard__bottom {
        padding-left: 0;
        padding-right: 0;
    }

}

@media (min-width: 1440px) {
    .whatwedocard__title {
        font-size: var(--fs-30);
        line-height: 35px;
        max-width: 275px;
    }

    .whatwedocard__description p {
        font-size: var(--fs-base);
        line-height: 24px;
    }

    .whatwedocard__logo {
        height: 80px;
        width: 80px;
    }
}



