

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.ContactPage__top {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
}

/* .ContactPage__top-card {
    flex: 1;
    max-width: 670px;
    min-width: 250px;
} */

.contact-subtitle--gray {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
}

.contact-google-map-box {
    margin-top: 50px;
}

@media (min-width: 1280px) {

    .google-map-div {
        height: 627px;
        width: 100%;
    }

    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .info-text-margin {
        margin-left: 230px;
    }

    .contact-rss-icon {
        height: 40px;
        margin-right: 5px;
        width: 40px;
    }

    .contact-rss-icon:hover {
        filter: grayscale(0.7);
    }

    .contact-subtitle--gray {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 47px;
        text-decoration: none;
    }

    .contact-subtitle--gray:hover {
        color: var(--font-color-hover-2);
    }

    .ContactPage__top {
        gap: 100px;
        justify-content: space-between;
    }

    .contact-google-map-box {
        margin-top: 20px;
    }

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    .google-map-div {
        height: 334px;
        width: 100%;
    }

    .contact-text-box {
        margin-top: 40px;
    }

    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .contact-box-main {
        margin-top: 40px;
    }

    .info-text-margin {
        margin-left: 115px;
    }

    .contact-rss-icon {
        height: 40px;
        margin-right: 5px;
        width: 40px;
    }

    .contact-subtitle--gray {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 40px;
    }

    .contact__link:hover {
        opacity: 0.5;
    }
    
  }

  /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .google-map-div {
        height: 334px;
        width: 100%;
    }

    .contact-text-box {
        margin-top: 40px;
    }

    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 40px;
    }

    .contact-box-main {
        margin-top: 40px;
    }

    .contact-rss-icon {
        height: 34px;
        margin-right: 5px;
        width: 34px;
    }

    .contact-subtitle--gray {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 40px;
    }
    
  }

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .google-map-div {
        height: 334px;
        width: 100%;
    }

    .contact-text-box {
        margin-top: 40px;
    }
    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 40px;
    }

    .contact-box-main {
        margin-top: 40px;
    }

    .info-text-margin {
        margin-left: 115px;
    }

    .contact-google-map-box {
        margin-top: 20px;
    }

    .contact-rss-icon {
        height: 34px;
        margin-right: 5px;
        width: 34px;
    }

    .contact-subtitle--gray {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 40px;
    }
}


/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
    .google-map-div {
        height: 334px;
        width: 100%;
    }

    .contact-text-box {
        margin-top: 30px;
    }

    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 20px;
    }

    .contact-box-main {
        margin-top: 40px;
    }

    .section {
        padding: 3rem 1.5rem 3rem 3rem;
    }

    .info-text-margin {
        margin-top: 30px;
    }

    .contact-rss-icon {
        height: 34px;
        margin-right: 5px;
        width: 34px;
    }

    .contact-subtitle--gray {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 20px;
    }
    
  }

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    .google-map-div {
        height: 334px;
        width: 100%;
    }

    .contact-text-box {
        margin-top: 30px;
    }

    .contact-subtitle--rss {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 20px;
    }

    .contact-box-main {
        margin-top: 40px;
    }

    /* .section {
        padding: 3rem 1.5rem 3rem 3rem;
    } */

    .info-text-margin {
        margin-top: 30px;
    }

    .contact-rss-icon {
        height: 34px;
        margin-right: 5px;
        width: 34px;
    }

    .contact-subtitle--gray {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 20px;
    }
}
