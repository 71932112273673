

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1280px) {

    .cookies-modal-div {
        bottom: 0px;
        height: 444px !important;
        left: 0px;
        position: absolute;
        width: 100% !important;
    }

    .cookies-modal-body {
        background-color: var(--bg-darkest);
        color: var(--font-color-secondary);
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 1px solid var(--color-white);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        margin-right: 20px;
        width: 280px;
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 1px solid var(--color-white);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        width: 280px;
    }

    .accepted-cookies-button {
        background-color: var(--bg-lightest);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        width: 280px;
    }

    .cookies-modal-subtitle {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-modal-logo {
        height: 40px;
        margin-top: 50px;
        width: 217px;
    }

    .cookies-modal-content {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 29px;
    }

    .modal-card {
        margin: 0;
    }

    .cookies-buttons-group {
        display: flex;
        justify-content: center;
    }

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    .cookies-modal-div {
        bottom: 0px;
        height: 444px !important;
        left: 0px;
        position: absolute;
        width: 100% !important;
    }

    .cookies-modal-body {
        background-color: var(--bg-darkest);
        color: var(--font-color-secondary);
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 1px solid var(--color-white);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        width: 280px;
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 1px solid var(--color-white);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        margin-right: 20px;
        width: 280px;
    }

    .accepted-cookies-button {
        background-color: var(--bg-lightest);
        border-radius: 10px;
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        margin-left: 20px;
        width: 280px;
    }

    .cookies-modal-subtitle {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-modal-logo {
        height: 40px;
        margin-top: 50px;
        width: 217px;
    }

    .cookies-modal-content {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 29px;
    }

    .modal-card {
        margin: 0;
    }

    .cookies-buttons-group {
        display: flex;
        justify-content: center;
    }

}


/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .cookies-modal-div {
        bottom: 0px;
        height: 480px !important;
        left: 0px;
        position: absolute;
        width: 100% !important;
    }

    .cookies-modal-body {
        background-color: var(--bg-darkest);
        color: var(--font-color-secondary);
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 1px solid var(--color-white);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 15px;
        width: 210px;
    }

    .accepted-cookies-button {
        background-color: var(--bg-lightest);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        width: 210px;
    }

    .cookies-modal-subtitle {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 6px;
    }

    .cookies-modal-logo {
        height: 40px;
        margin-top: 52px;
        width: 217px;
    }

    .cookies-modal-content {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-16);
        letter-spacing: 0;
        line-height: 19px;
        margin-top: 6px;
    }

    .modal-card {
        margin: 0;
    }

    .cookies-buttons-group {
        display: flex;
        justify-content: center;
    }
}


@media (min-width: 320px) and (max-width: 1023px) {
    
    .cookies-modal-div {
        bottom: 0px;
        height: 480px !important;
        left: 0px;
        position: absolute;
        width: 100% !important;
    }

    .cookies-modal-body {
        background-color: var(--bg-darkest);
        color: var(--font-color-secondary);
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 0.75px solid var(--color-white);
        border-radius: 8px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    .accepted-cookies-button {
        background-color: var(--bg-lightest);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    .cookies-modal-subtitle {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-modal-logo {
        height: 30px;
        margin-top: 42px;
        width: 163px;
    }

    .cookies-modal-content {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 29px;
    }

    .modal-card {
        margin: 0;
    }
}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    .cookies-modal-div {
        bottom: 0px;
        height: 480px !important;
        left: 0px;
        position: absolute;
        width: 100% !important;
    }

    .cookies-modal-body {
        background-color: var(--bg-darkest);
        color: var(--font-color-secondary);
    }

    .configuration-cookies-button {
        background-color: var(--bg-darkest);
        border: 0.75px solid var(--color-white);
        border-radius: 8px;
        box-sizing: border-box;
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    .accepted-cookies-button {
        background-color: var(--bg-lightest);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    .cookies-modal-subtitle {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-modal-logo {
        height: 30px;
        margin-top: 42px;
        width: 163px;
    }

    .cookies-modal-content {
        color: var(--font-color-secondary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 29px;
    }

    .modal-card {
        margin: 0;
    }

}

