.form-checkbox {
  cursor: pointer;
  display: grid;
  font-size: var(--fs-16);
  gap: 10px;
  grid-template-columns: 20px auto;
  line-height: 1.3em;
  position: relative;
}

.form-checkbox:focus,
.form-checkbox:active {
  outline: none;
}

.form-checkbox:focus .form-checkbox__content::before {
  border-color: var(--color-dusty-gray);
}

.form-checkbox__input {
  height: 20px;
  left: -9999px;
  position: absolute;
  width: 20px;
}

.form-checkbox__content {
  color: var(--font-color-quaternary);
  display: block;
  font-size: var(--fs-16);
  font-weight: var(--font-weight-medium);
  line-height: 19px;
  position: relative;
}

.form-checkbox__content a {
  color: var(--font-color-quaternary);
  font-size: var(--fs-16);
  font-weight: var(--font-weight-semi-bold);
}

.form-checkbox__content::before,
.form-checkbox__content::after {
  box-sizing: border-box;
  position: absolute;
  transition: .3s;
}

.form-checkbox__box {
  background-color: var(--bg-lightest);
  border: 1px solid var(--form-control-color);
  border-radius: 3px;
  height: 14px;
  padding: 8px;
  position: relative;
  width: 14px;
}

.form-checkbox__check {
  fill: var(--form-control-color);
  height: 10px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}

.form-checkbox__input:checked + .form-checkbox__box > .form-checkbox__check {
  opacity: 1;
}

.form-checkbox__input:focus + .form-checkbox__content::before {
  border-color: var(--form-control-color);
}
