/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.cookiesPage {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    word-break: break-word;
}

@media (min-width: 1280px) {
    .cookies-card1 h1 {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card1 div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card1 ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card1 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card2 h1 {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card2 div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card2 ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card2 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card3 h1 {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card3 div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card3 ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card3 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th, td {
        border-bottom: 1px solid var(--table-border-color);
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .cookies-table table th {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    .cookies-button-position {
        display: flex;
        justify-content: center;
    }

    .is-cookies-button {
        background-color: var(--bg-darkest);
        border-radius: 12px;
        color: var(--font-color-secondary);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 280px;
    }

    .cookies-table table td a{
        background-image: url('/src/assets/svg/external.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        margin-left: 10px;
        width: 15px;
    }

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .cookies-card1 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card1 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card1 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card1 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card2 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card2 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card2 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card2 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card3 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card3 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card3 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card3 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th, td {
        border-bottom: 1px solid var(--table-border-color);
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .cookies-table table th {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    /* .section {
        padding: 3rem 1.5rem 3rem 1.5rem;
    } */

    .cookies-button-position {
        display: flex;
        justify-content: center;
    }

    .is-cookies-button {
        background-color: var(--bg-darkest);
        border-radius: 12px;
        color: var(--font-color-secondary);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        height: 60px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 280px;
    }

    .cookies-table table td a{
        background-image: url('/src/assets/svg/external.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        margin-left: 10px;
        width: 15px;
    }

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .cookies-card1 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-card1 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card1 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card1 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card2 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-card2 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card2 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card2 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card3 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-card3 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card3 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card3 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th, td {
        border-bottom: 1px solid var(--table-border-color);
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .cookies-table table th {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    .section {
        padding: 3rem 1.5rem 0 1.5rem;
    }

    .cookies-button-position {
        display: flex;
        justify-content: center;
    }

    .is-cookies-button {
        background-color: var(--bg-darkest);
        border-radius: 12px;
        color: var(--font-color-secondary);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 235px;
    }

    .cookies-table table td div{
        background-color: var(--bg-blue);
        background-repeat: no-repeat;
        height: 20px;
        width: 10px;
    }

    .cookies-table table td a{
        background-image: url('/src/assets/svg/external.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        margin-left: 10px;
        width: 15px;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .cookies-card1 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card1 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card1 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card1 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card2 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card2 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card2 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card2 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card3 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
    }

    .cookies-card3 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card3 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card3 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th, td {
        border-bottom: 1px solid var(--table-border-color);
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .cookies-table table th {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    /* .section {
        padding: 3rem 1.5rem 3rem 1.5rem;
    } */

    .cookies-button-position {
        display: flex;
        justify-content: center;
    }

    .is-cookies-button {
        background-color: var(--bg-darkest);
        border-radius: 12px;
        color: var(--font-color-secondary);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 321px;
    }

    .cookies-table table td a{
        background-image: url('/src/assets/svg/external.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        margin-left: 10px;
        width: 15px;
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .cookies-card1 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-18);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 21px;
        margin-top: 40px;
    }

    .cookies-card1 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card1 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card1 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card2 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-18);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 21px;
    }

    .cookies-card2 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card2 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card2 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-card3 h1 {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-18);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 21px;
    }

    .cookies-card3 div {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card3 ul {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-card3 ul li {
        padding: 0 0 0 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 30px;
        width: 859px;
    }

    .cookies-table table th, td {
        border-bottom: 1px solid var(--table-border-color);
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .cookies-table table th {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 18px;
        letter-spacing: 0;
        line-height: 18px;
        width: 148px;
    }

    .cookies-table table td {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    .cookies-button-position {
        display: flex;
        justify-content: center;
    }

    .is-cookies-button {
        background-color: var(--bg-darkest);
        border-radius: 12px;
        color: var(--font-color-secondary);
        font-size: var(--fs-base);
        font-weight: var(--font-weight-bold);
        height: 45px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 321px;
    }

    .cookies-table table td a{
        background-image: url('/src/assets/svg/external.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 15px;
        margin-left: 10px;
        width: 15px;
    }

}