body {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

html {
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100vw;
}
body {
  min-height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-very-light-grey: #CCCCCC;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-white-smoke: #F5F5F5;
  --color-gainsboro: #DDDDDD;
  --color-charcoal: #414141;
  --color-aqua: aqua;
  --color-matterhorn: #4C4C4C;
  --color-suva-grey: #8A8A8A;
  
  --typography-content:  Raleway, serif;

  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-super-bold: 900;
  
  --font-color-primary: var(--color-black);
  --font-color-secondary: var(--color-white);
  --font-color-tertiary: var(--color-matterhorn);
  --font-color-quaternary: var(--color-suva-grey);
  --font-color-hover: var(--color-charcoal);
  --font-color-hover-2: var(--color-matterhorn);
  
  --table-border-color: var(--color-gainsboro);

  --button-primary-bg: var(--color-black);
  --button-primary-color: var(--font-color-secondary);
  --button-primary-disabled-color: var(--font-color-primary);

  --button-inverted-bg: var(--color-white);
  --button-inverted-color: var(--font-color-primary);
  --button-inverted-disabled-color: var(--font-color-tertiary);

  --bg-lightest: var(--color-white);
  --bg-very-light: var(--color-white-smoke);
  --bg-light: var(--color-very-light-grey);
  --bg-medium: var(--color-matterhorn);
  --bg-darkest: var(--color-black);
  --bg-blue: var(--color-aqua);

  --mobile-xs-size: 375px;
  --mobile-size: 480px;
  --tablet-size: 1024px;
  --desktop-size: 1440px;
  --desktop-l-size: 1680px;
  --desktop-xl-size: 2000px;

  --fs-12: 12px;
  --fs-15: 15px;
  --fs-16: 16px;
  --fs-17: 17px;
  --fs-18: 18px;  
  --fs-20: 20px;
  --fs-22: 22px;
  --fs-25: 25px;
  --fs-29: 29px;
  --fs-30: 30px;
  --fs-35: 35px;
  --fs-40: 40px;
  --fs-45: 45px;
  --fs-50: 50px;
  --fs-54: 54px;
  --fs-60: 60px;
  --fs-70: 70px;
  --fs-72: 72px;
  --fs-90: 90px;
  
  --fs-base: var(--fs-20);

  --fs-footer-s: var(--fs-12);
  --fs-footer: var(--fs-base);

  --fs-title-xs: var(--fs-35);
  --fs-title-s: var(--fs-40);
  --fs-title-m: var(--fs-50);
  --fs-title-l: var(--fs-90);

  --form-control-color: var(--color-black);
  --form-control-disabled: var(--color-suva-grey);

  --z-index-0: 0;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 9;
  --z-index-4: 10;
  --z-index-5: 5;
  --z-index-6: 6;
  --z-index-7: 7;
  --z-index-8: 8;
  --z-index-9: 9999;
  --z-index-10: 10;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App--overflow--hidden {
  overflow: hidden;
}

