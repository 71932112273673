.whatWeDo-narrow-icon {
    display: inline-block;
    height: 34px;
    vertical-align: middle;
    width: 34px;
}

@media (min-width: 768px){
    .whatWeDo-narrow-icon {
        vertical-align: sub;
    }
}

@media (min-width: 1440px) {
    .whatWeDo-title-text {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-title-l);
        font-weight: var(--font-weight-super-bold);
        height: 106px;
        letter-spacing: 0;
        line-height: 106px;
        text-align: right;
        text-transform: uppercase;
        width: 655px;
    }

    .link-project {
        margin-top: 107px;
    }

    .whatWeDo-block3-style {
        margin-bottom: 100px !important;
        margin-top: 109px;
    }

    .whatWeDo-contact-subtitle a:hover {
        color: var(--font-color-hover);
    }
    .move-left {
        margin-left: 230px;
    }
}



@media (min-width: 1024px) and (max-width: 1440px) {
    .whatWeDo-title-text {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-60);
        font-weight: var(--font-weight-super-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 70px;
        text-align: right;
        text-transform: uppercase;
        width: 437px;
    }

    .link-project {
        margin-top: 107px;
    }

    .whatWeDo-block3-style {
        margin-bottom: 100px !important;
        margin-top: 109px;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .whatWeDo-title-text {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-60);
        font-weight: var(--font-weight-super-bold);
        height: 70px;
        letter-spacing: 0;
        line-height: 70px;
        text-align: right;
        text-transform: uppercase;
        width: 437px;
    }

    .link-project {
        margin-top: 107px;
    }

    .whatWeDo-block3-style {
        margin-bottom: 100px !important;
        margin-top: 109px;
    }
}


@media (min-width: 481px) and (max-width: 1023px) {
    .whatWeDo-title-text {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-title-m);
        font-weight: var(--font-weight-super-bold);
        letter-spacing: 0;
        line-height: 47px;
        margin-top: 30px;
        text-align: right;
        text-transform: uppercase;
    }

    .link-project {
        margin-top: 107px;
    }

    .whatWeDo-block3-style {
        margin-bottom: 50px !important;
        margin-top: 50px;
    }
}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .whatWeDo-title-text {
        color: var(--font-color-primary);
        font-family: var(--typography-content);
        font-size: var(--fs-40);
        font-weight: var(--font-weight-super-bold);
        letter-spacing: 0;
        line-height: 47px;
        margin-top: 30px;
        text-align: right;
        text-transform: uppercase;
    }

    .link-project {
        margin-top: 107px;
    }

    .whatWeDo-block3-style {
        margin-bottom: 50px !important;
        margin-top: 50px;
    }
}
