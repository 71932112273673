.JamenetReviewView__content-container {
  background-color: var(--bg-very-light);
  padding-bottom: 2rem;
}

.JamenetReviewView__content-container--success {
  background-color: var(--bg-light);
}

.JamenetReviewView__content-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.JamenetReviewView__magazine-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.JamenetReviewView__magazine-grid div {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  text-align: center; 
}

.JamenetReviewView__magazine-grid .button {
  min-width: 280px;
}

.JamenetReviewView__magazine-grid .JamenetReviewView__magazine-grid-left {
  align-items: center;
}

.JamenetReviewView__content-right {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 20px 0px 20px 0px;
}

.JamenetReviewView__magazine-grid-right {
  color: var(--font-color-primary);
  font-family: var(--typography-content);
  padding-bottom: 50px;
}

.JamenetReviewView__magazine-grid-right div {
  align-items: center;
}

.JamenetReviewView__magazine-grid-right p {
  font-size: var(--fs-22);
  font-weight: var(--font-weight-medium);
  line-height: 37px;
}

.JamenetReviewView__subtitle {
  font-size: var(--fs-29);
  font-weight: var(--font-weight-bold);
  line-height: 35px;
}

.JamenetReviewView__magazine-title {
  font-family: var(--typography-content);
  font-size: var(--fs-40);
  font-weight: var(--font-weight-bold);
  line-height: 35px
}

.JamenetReviewView__content {
  font-family: var(--typography-content);
  font-size: var(--fs-base);
  font-weight: var(--font-weight-medium);
  line-height: 26px;
}

.JamenetReviewView__content-form {
  display: flex;
  flex-direction: column;
  gap: 40px
}

.JamenetReviewView__content-magazine img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media (min-width: 1024px) {
  .JamenetReviewView__content-form {
    display: grid;
    gap: 60px;
    grid-template-columns: 1fr 1fr
  }

  .JamenetReviewView__content-grid {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 1fr 2fr;
  }

  .JamenetReviewView__magazine-grid {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 1fr 1fr;
  }

  .JamenetReviewView__content-container {
    padding-bottom: 0rem;
  }

  .JamenetReviewView__magazine-grid div {
    align-items: flex-start;
    text-align: flex-start;
  }

  .JamenetReviewView__magazine-grid .JamenetReviewView__magazine-grid-left {
    align-items: flex-end;
  }

  .JamenetReviewView__content-container .Container_container {
    width: 100%;
  }

  .JamenetReviewView__content-right {
    max-width: 57vw;
    padding: 30px 5vw 30px 0px;
  }

  .JamenetReviewView__magazine-grid-right {
    padding-bottom: 0px;
  }
  
}

@media (min-width: 1680px) {
  .JamenetReviewView__content-grid {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 770px 2fr;
  }

}