.linkWithArrow {
  align-items: center;
  color: var(--font-color-primary);
  display: flex;
  gap: 5px;
}

.linkWithArrow:hover {
  color: var(--font-color-hover);
  opacity: 0.5;
}

.linkWithArrow__arrow {
  display: inline-block;
  height: 34px;
  width: 34px;
}

.linkWithArrow__message {
  color: var(--font-color-primary);
  display: inline-block;
  font-family: var(--typography-content);
  font-size: var(--fs-base);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0;
  line-height: 24px;
}

.linkWithArrow__message:hover {
  color: var(--font-color-hover);
}

@media (min-width: 1440px) {
  .linkWithArrow__message {
      font-size: var(--fs-30);
      line-height: 35px;
  }
}


