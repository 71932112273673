.contactCard {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-evenly;
}

.contactCard.space-between {
  justify-content: space-between;
}

.contactCard-column {
  flex: 1;
  max-width: 800px;
  min-width: 220px;
}

.contactCard-title {
  color: var(--font-color-primary);
  font-family: var(--typography-content);
  font-size: var(--fs-25);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0;
  line-height: 30px;
}

.contactCard-content,
.contactCard-content a {
  color: var(--font-color-primary);
  font-family: var(--typography-content);
  font-size: var(--fs-base);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0;
  line-height: 24px;
}

.contactCard-content a {
  text-decoration: none;
}


.contactCard-content a:hover {
  color: var(--font-color-hover-2);
}

.contactCard-column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (min-width: 768px) {
  .contactCard-title {
      font-size: var(--fs-35);
      line-height: 41px;
  }
}

@media (min-width: 1024px) {
  .contactCard-title {
      color: var(--font-color-primary);
      font-family: var(--typography-content);
      font-size: var(--fs-title-m);
      font-weight: var(--font-weight-bold);
      letter-spacing: 0;
      line-height: 59px;
  }

  .contactCard-content,
  .contactCard-content a {
      font-size: var(--fs-30);
      font-weight: var(--font-weight-semi-bold);
      line-height: 35px;
  }

  .contactCard-column {
      display: flex;
      flex-direction: column;
      gap: 40px;
  }
}

@media (min-width: 1280px) {
  .contactCard-box {
      margin-bottom: 100px !important;
      margin-top: 100px;
  }
}

