.PageTitle {
  color: var(--font-color-primary);
  font-family: var(--typography-content);
  font-size: var(--fs-title-xs);
  font-weight: var(--font-weight-super-bold);
  letter-spacing: 0;
  line-height: 47px;
  text-align: right;
  text-transform: uppercase;
}

.PageTitle h1 {
  font-weight: var(--font-weight-super-bold);
}

.PageTitle__smaller h1 {
  color: var(--font-color-primary);
  font-family: var(--typography-content);
  font-size: var(--fs-35);
  font-weight: var(--font-weight-bold);
  line-height: 41px;
}


@media (min-width: 1440px) {
  .PageTitle {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    font-size: var(--fs-title-l);
    font-weight: var(--font-weight-super-bold);
    letter-spacing: 0;
    line-height: 106px;
    text-align: right;
    text-transform: uppercase;
  }
  
  .PageTitle h1 {
    font-weight: var(--font-weight-super-bold);
  }

  .PageTitle__smaller h1 {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    font-size: var(--fs-title-m);
    font-weight: var(--font-weight-bold);
    line-height: 59px;
  }
  
}
