.subtitle-text {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
    font-size: var(--fs-18);
    font-weight: var(--font-weight-semi-bold);
    height: 73px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 14px;
}

.is-home-button {
    background-color: var(--bg-darkest);
    border-radius: 12px;
    color: var(--font-color-secondary);
    font-family: var(--typography-content);
    font-size: var(--fs-base);
    font-weight: var(--font-weight-bold);
    height: 48px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 14px;
    max-width: 320px;
    text-align: center;
}

.burger-img-position {
    display: flex;
    justify-content: flex-end;
}

.is-burger-img {
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.home-logo {
    height: auto;
    margin-top: 172px;
    max-width: 100%;
    width: 319px;
}

.home-icons {
    height: 48px;
    width: 47px;
}

.av-icon {
    height: 48px;
    width: 47px;
}

.menus-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.logo-box {
    display: flex;
    flex-wrap: wrap;
    height: 70px;
    justify-content: space-between;
    margin-bottom: 80px;
    margin-top: 28px;
    width: 320px;
}

@media (min-width: 320px) and (max-width: 1023px) {
    .subtitle-text {
        width: 319px;
    }
}

@media (min-width: 1024px) {
    .home__header {
        padding-top: 80px;
    }

    .home-logo {
        margin-top: 70px;
        width: 769px;
    }

    .is-home-button {
        height: 60px;
        margin-top: 5px;
        width: 440px;
    }

    .subtitle-text {
        font-size: var(--fs-30);
        height: 96px;
        line-height: 35px;
        margin-top: 50px;
        width: 769px;
    }

    .home-icons {
        height: 70px;
        transition: all .3s ease-out;
        width: 70px;
    }

    .home-icons:hover ~ .home-icons {
        margin-top: 10px;
    }

    .av-icon {
        height: 70px;
        width: 70px;
    }

    .av-icon:hover {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        transform: translateY(-5px);
    }

    .logo-box {
        display: flex;
        height: 70px;
        justify-content: space-between;
        margin-bottom: 120px;
        width: 440px;
    }

    .is-home-button:hover {
        background-color: var(--bg-medium);
        color: var(--font-color-secondary);
    }

    .menus-container {
        align-items: center;
        justify-content: flex-start;
    }

}

@media (min-width: 1280px) {
    .home-logo {
        margin-top: 90px;
    }

    .is-home-button {
        height: 60px;
        margin-top: 5px;
        width: 440px;
    }

    .is-home-button:hover {
        background-color: var(--bg-medium);
        color: var(--font-color-secondary);
    }

    .home-icons-active {
        height: 70px;
        transition: all .3s ease-out;
        width: 70px;
    }

    .home-icons-active:hover {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        transform: translateY(-5px);
    }

    .logo-box {
        margin-bottom: 130px;
    }

}

