/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.cookies-config-subtitle {
    color: var(--font-color-primary)
}

.cookies-card ul,
.cookies-table table th,
.cookies-table table td,
.cookies-card div, 
.cookies-config-subtitle {
    color: var(--font-color-primary);
    font-family: var(--typography-content);
}

.cookies-table table th, td {
    border-bottom: 1px solid var(--table-border-color);
    padding-bottom: 16px;
    padding-top: 16px;
}

.cookies-card ul li {
    padding: 0 0 0 20px;
} 

@media (min-width: 1280px) {
    .cookies-config-subtitle {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .cookies-config-subtitle {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .cookies-config-subtitle {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 30px;
    }

    .cookies-card div {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 30px;
    }

    .cookies-card ul {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th {
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

    .section {
        padding: 3rem 1.5rem 0 1.5rem;
    }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 1023px) {
    .cookies-config-subtitle {
        font-size: var(--fs-30);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 35px;
        margin-top: 40px;
    }

    .cookies-card div {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 30px;
    }

    .cookies-card ul {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 28px;
        list-style: disc;
        margin-left: 10px;
        margin-top: 20px;
    }

    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 100px;
        width: 100%;
    }

    .cookies-table table th {
        font-size: var(--fs-base);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td {
        font-size: var(--fs-base);
        letter-spacing: 0;
        line-height: 24px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .cookies-table table {
        border-collapse: collapse;
        margin-bottom: 30px;
        width: 859px;
    }

    .cookies-table table th {
        font-size: var(--fs-15);
        font-weight: var(--font-weight-semi-bold);
        height: 18px;
        letter-spacing: 0;
        line-height: 18px;
        width: 148px;
    }

    .cookies-table table td {
        font-size: var(--fs-15);
        letter-spacing: 0;
        line-height: 18px;
    }

    .cookies-table table td:nth-child(1) {
        width: 331px;
    }

    .cookies-table table td:nth-child(2) {
        width: 838px;
    }

    .cookies-table table th:nth-child(4) {
        text-align: right;
    }

    .cookies-table table td:nth-child(4) {
        text-align: right;
    }

}