/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
.footer-bg {
    background-color: var(--bg-darkest);
}

.is-footer-text,
.is-footer-text a {
    color: var(--font-color-secondary);
    display: block;
    font-family: var(--typography-content);
}

@media (min-width: 1280px) {
    .footer-box {
        display: flex;
        justify-content: space-around;
    }

    /* CSS */
    .footer-bg {
        height: 200px;
    }

    .is-footer-text {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
    }

    .footer-brand-img{
        float: right;
        height: 14px;
        width: 130px;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .footer-icons {
        height: 30px;
        width: 30px;
    }

   .icons-position a:hover {
       filter: brightness(0.7);
   }

    .footer-link a:hover {
        color: var(--font-color-hover);
        cursor: pointer;
        text-decoration: none;
    }

    .is-footer-text a {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: none;
    }

    .is-footer-text a:hover {
        color: var(--font-color-hover);
    }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

    .footer-bg {
        height: 180px;
    }

    .footer-box {
        display: flex;
        justify-content: space-around;
    }

    .is-footer-text {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .footer {
        padding: 1.5rem;
    }

    .footer-div {
        margin-top: 10px;
    }

    .icons-position a:hover {
        filter: brightness(0.7);
    }
}


/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    /* CSS */
    .footer-bg {
        height: 180px;
    }

    .footer-box {
        display: flex;
        justify-content: space-around;
    }

    .is-footer-text {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }
    #flex { display: flex; }
    #a { order: 1; }
    #b { order: 2; }
    #c { order: 3; }

    .icons-position {
        display: flex;
        justify-content: center;
    }

    .footer {
        padding: 1.5rem;
    }

    .footer-div {
        margin-top: 10px;
    }
    
}

@media (min-width: 481px) and (max-width: 1023px) {

    /* CSS */
    .footer-bg {
        height: 258px;
    }

    .footer-box {
        display: flex;
        flex-wrap: wrap;
    }

    .is-footer-text {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    #flex { display: flex; }
    #a { order: 2; width: 50%; }
    #b { order: 1; width: 100%;}
    #c { order: 3; width: 50%;}

    .icons-position {
        display: flex;
        justify-content: center;
    }

}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    .footer-bg {
        height: 232px;
    }

    .footer-box {
        display: flex;
        flex-wrap: wrap;
    }

    .is-footer-text {
        font-size: var(--fs-footer);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }

    .footer-brand-img{
        float: right;
        height: 8px;
        width: 92px;
    }

    #flex { display: flex; }
    #a { order: 2; width: 50%; }
    #b { order: 1; width: 100%;}
    #c { order: 3; width: 50%;}

    .column {
        padding-bottom: 0;
    }

    .icons-position {
        display: flex;
        justify-content: center;
    }

}
