.button {
  border-radius: 12px;
  font-family: var(--typography-content);
  font-size: var(--fs-base);
  font-weight: var(--font-weight-bold);
  height: 60px;
  letter-spacing: 0;
  line-height: 24px;
  outline: 0px;
  text-align: center;
}

.button:focus {
  outline: none;
}

.button--primary {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-color);
}

.button--inverted {
  background-color: var(--button-inverted-bg);
  color: var(--button-inverted-color);
}

.button--primary:disabled {
  color: var(--button-primary-disabled-color)
}

.button--inverted:disabled {
  color: var(--button-inverted-disabled-color)
}